/* font-family */
#wrap {
  margin-top: 0;
}

#main-view-wrap {
  position: relative;
}

#main-view {
  width: 100%;
  height: 100vh;
}

#main-view .in {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#main-view span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-position: center 60%;
  background-size: cover;
  transition: transform 12s linear, opacity .5s linear;
}

#main-view span.show {
  opacity: 1;
}

#main-view span:nth-child(even) {
  transform: scale(1.2);
}

#main-view span:nth-child(even).show {
  transform: scale(1);
}

#main-view span:nth-child(odd) {
  transform: scale(1);
}

#main-view span:nth-child(odd).show {
  transform: scale(1.2);
}

#main-view:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6) url(../svg/common/bg_grid.svg);
  background-size: 50%;
}

#slides-nav {
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  position: absolute;
  bottom: 20px;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, 0);
  transform-origin: top right;
}

#slides-nav > span {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}

#slides-idx em {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
}

#slides-idx em b {
  display: block;
  width: 3px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.25);
}

#slides-idx em:hover b {
  background-color: rgba(255, 255, 255, 0.5);
}

#slides-idx em.active b {
  background-color: white;
}

#slides-num {
  font-size: 24px;
}

#main-caption {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}

#main-caption .h-sm p {
  opacity: .8;
  margin: 0 0 10px;
}

#main-view-wrap {
  margin: 79px 0 0;
}

@media screen and (min-width: 800px) {
  #main-view-wrap {
    margin: 0;
  }
}

#precautionary .pre-head {
  text-align: center;
}

@media screen and (min-width: 800px) {
  #precautionary .pre-head h3 {
    display: flex;
    justify-content: center;
  }
}

#precautionary .pre-head h3 i {
  display: block;
  font-size: 2.0em;
  margin: 0 0 10px;
}

@media screen and (min-width: 800px) {
  #precautionary .pre-head h3 i {
    font-size: 1.4em;
    margin: 0 10px 0 0;
  }
}

#precautionary .pre-body {
  margin: 20px 0 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (min-width: 600px) {
  #precautionary .pre-body {
    padding: 30px;
  }
}

@media screen and (min-width: 800px) {
  #precautionary .pre-body {
    padding: 40px;
  }
}

@media screen and (min-width: 1200px) {
  #precautionary .pre-body {
    padding: 60px;
  }
}

#precautionary .pre-body p:not(:first-child) {
  margin: 20px 0 0;
}

@media screen and (min-width: 1200px) {
  #precautionary .pre-foot {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}

#precautionary .pre-foot .pre-note {
  margin: 25px 0 0;
}

@media screen and (min-width: 1000px) {
  #precautionary .pre-foot .pre-note {
    width: calc(50% - 30px);
    margin: 25px 15px 0;
  }
}

#precautionary .pre-foot .pre-note h4 {
  font-size: 1.2em;
}

#precautionary .pre-foot .pre-note ul li {
  display: flex;
}

#precautionary .pre-foot .pre-note ul li i {
  width: 1em;
  margin-right: 5px;
}

#news-list article:nth-child(n+4) {
  display: none;
}

@media screen and (min-width: 600px) {
  #main-view:before {
    background-size: 33.333333%;
  }
  #news-list article:nth-child(n+4) {
    display: inline-block;
  }
}

@media screen and (min-width: 800px) {
  #main-caption {
    text-align: left;
    padding: 0 0 0 60px;
  }
  #main-caption .h-sm {
    margin-left: .4em;
  }
}

@media screen and (min-width: 1000px) {
  #main-view:before {
    background-size: 25%;
  }
  #slides-nav {
    bottom: auto;
    left: auto;
    top: 70%;
    right: 30px;
    transform: translate(0, -50%) rotate(90deg);
  }
  #slides-num {
    transform: rotate(-90deg);
  }
  #main-caption {
    padding: 0 0 0 70px;
  }
}

@media screen and (min-width: 1200px) {
  #main-view:before {
    background-size: 20%;
  }
  #main-caption {
    padding: 0 0 0 80px;
  }
}
