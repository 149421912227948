@charset "utf-8";

@import "variables";


#wrap{
	margin-top: 0;
}


#main-view-wrap{
	position: relative;
}

#main-view{
	width: 100%;
	height: 100vh;
	.in{
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	span{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		background-position: center 60%;
		background-size: cover;
		transition: transform 12s linear,
					opacity .5s linear;
	}
	span.show{
		opacity: 1;
	}
	span:nth-child(even) {
		transform: scale(1.2);
	}
	span:nth-child(even).show{
		transform: scale(1);
	}
	span:nth-child(odd) {
		transform: scale(1);
	}
	span:nth-child(odd).show{
		transform: scale(1.2);
	}
}
#main-view:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	// background-color: rgba(0,0,0,.6);
	background: rgba(0,0,0,.6) url(../svg/common/bg_grid.svg);
	background-size: 50%;
}
#slides-nav{
	text-align: center;
	white-space: nowrap;
	line-height: 1;
	position: absolute;
	bottom: 20px;
	left: 50%;
	z-index: 3;
	// width: 100%;
	transform: translate(-50%, 0);
	transform-origin: top right;
	> span{
		display: inline-block;
		vertical-align: middle;
		margin: 0 5px;
	}
}
#slides-idx{
	em{
		cursor: pointer;
		display: inline-block;
		// margin: 0 5px;
		padding: 0 10px;
		b{
			display: block;
			width: 3px;
			height: 20px;
			background-color: rgba(255,255,255,.25);
		}
	}
	em:hover{
		b{
			background-color: rgba(255,255,255,.5);
		}
	}
	em.active{
		b{
			background-color: rgba(255,255,255,1);
		}
	}
}
#slides-num{
	font-size: 24px;
}

#main-caption{
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
	width: 100%;
	height: auto;
	transform: translate(-50%, -50%);
	.h-sm{
		p{
			opacity: .8;
			margin: 0 0 10px;
		}
	}
}


#main-view-wrap {
	margin: 79px 0 0;
	@media screen and (min-width: 800px) {
		margin: 0;
	}
}
#precautionary {
	.pre-head {
		text-align: center;
		h3 {
			@media screen and (min-width: 800px) {
				display: flex;
				justify-content: center;
			}
			i {
				display: block;
				font-size: 2.0em;
				margin: 0 0 10px;
				@media screen and (min-width: 800px) {
					font-size: 1.4em;
					margin: 0 10px 0 0;
				}
			}
		}
	}
	.pre-body {
		margin: 20px 0 0;
		padding: 20px;
		background-color: rgba(255,255,255,.1);
		@media screen and (min-width: 600px) {
			padding: 30px;
		}
		@media screen and (min-width: 800px) {
			padding: 40px;
		}
		@media screen and (min-width: 1200px) {
			padding: 60px;
		}
		p {
			&:not(:first-child) {
				margin: 20px 0 0;
			}
		}
	}
	.pre-foot {
		@media screen and (min-width: 1200px) {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
		}
		.pre-note {
			margin: 25px 0 0;
			@media screen and (min-width: 1000px) {
				width: calc(50% - 30px);
				margin: 25px 15px 0;
			}
			h4 {
				font-size: 1.2em;
			}
			ul {
				li {
					display: flex;
					i {
						width: 1em;
						margin-right: 5px;
					}
				}
			}
		}
	}
}


.sec.sec03{

}
#news-list{
	article:nth-child(n+4){
		display: none;
	}
}


@media screen and (min-width: 600px) {
	#main-view:before{
		background-size: 33.333333%;
	}

	#news-list{
		article:nth-child(n+4){
			display: inline-block;
		}
	}
}


@media screen and (min-width: 800px) {
	#main-view:before{
		// background-size: 25%;
	}

	#main-caption{
		text-align: left;
		padding: 0 0 0 60px;
		.h-sm{
			margin-left: .4em;
		}
	}
}


@media screen and (min-width: 1000px) {
	#main-view:before{
		background-size: 25%;
	}
	#slides-nav{
		bottom: auto;
		left: auto;
		top: 70%;
		right: 30px;
		transform: translate(0, -50%) rotate(90deg);
	}
	#slides-num{
		transform: rotate(-90deg);
	}

	#main-caption{
		padding: 0 0 0 70px;
	}

}


@media screen and (min-width: 1200px) {
	#main-view:before{
		background-size: 20%;
	}

	#main-caption{
		padding: 0 0 0 80px;
	}
}